.abacus-ai-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  position: relative;
  transition: all 0.3s linear;
}

.main-ai-container.viewing-pdf {
  width: 50%;
  padding: 25px 15px 25px 15px;
  margin: 25px 12px 25px 25px;
  transition: all 0.3s linear;
}

.pdf-ai-container {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  margin: 0px;
  overflow: hidden;
  width: 0%;
  transition: all 0.3s linear;
  transition-delay: 2s;
  height: 85%;
  background-color: white;
  position: relative;
  border-radius: 17px 17px 17px 17px;
  /* align-items: center; */
  box-shadow: 0px 4px 4px 0px #00000040;
  /* margin: 55px 0px; */
  transition: all 0.3s linear;
}

.pdf-ai-container.viewing-pdf {
  margin: 25px 25px 25px 12px;
  padding: 0px 0px 50px 0px;
  width: 50%;
  transition: all 0.3s linear;
}

.ai-header {
  position: relative;
  text-align: left;
  height: 55px;
  width: 100%;
  margin-bottom: 10px;
}

.ai-logo {
  float: left;
  height: 55px;
  object-fit: contain;
  overflow: hidden;
  padding: 0px 15px;
}

.ai-header-divider {
  width: 40%;
  padding: 10px;
  height: 2px;
  border-bottom: 2px solid #F9CB5A;
  margin: auto
}

.ai-chat-window {
  width: 100%;
  /* height:100%; */
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 10px;
}

.ai-message-container,
.user-message-container {
  padding: 0px 15px;
}

.ai-message-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.user-message-container {
  display: flex;
  justify-content: end;
}

.ai-message-settings {
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.ai-content {
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
}

.ai-message {
  background: #FAFAFA;
  border-radius: 17px 17px 17px 0px;
  border: 1px solid #F9CB5A;
  color: #535353;
  float: left;
  font-family: "ManropeLight";
  font-size: 0.95em;
  line-height: 1.6em;
  margin: 5px 200px 5px 0px;
  max-width: 90%;
  min-width: 40px;
  overflow: hidden;
  padding: 15px 20px;
  text-align: left;
  word-wrap: break-word;
}

.ai-message p {
  margin: 0px;
  text-align: left;
  font-family: "ManropeLight";
}

.ai-sources {
  background: #FAFAFA;
  border-radius: 17px 17px 17px 0px;
  border: 1px solid #F9CB5A;
  color: #535353;
  float: left;
  font-family: "ManropeLight";
  font-size: 0.95em;
  line-height: 1.6em;
  margin: 5px 200px 5px 0px;
  max-width: 90%;
  min-width: 40px;
  overflow: hidden;
  padding: 15px 20px;
  text-align: left;
  word-wrap: break-word;
}

.ai-sources h1 {
  margin: 0px;
  font-size: 1.2em;
  text-align: left;
  font-family: "ManropeRegular";
}

.ai-sources-list {
  margin: 5px 0px 0px 0px;
  padding: 0px 0px 0px 25px;
}

.ai-sources p {
  margin: 0px;
  font-size: 0.9em;
  text-align: left;
  font-family: "ManropeLight";
}

.ai-sources-button {
  background-color: transparent;
  border: none;
  padding: 0px;
  font-size: 0.9em;
  text-align: left;
  font-family: "ManropeLight";
  color:#F0581A;
  text-decoration: underline;
  cursor: pointer;
}

.ai-message-button-setting {
  background-color: #f29e4900;
  border-color: #F7931E;
  border-radius: 80px 80px 80px 80px;
  border-style: solid;
  border-width: 2px;
  color: #F7931E;
  font-size: 0.95em;
  font-weight: 400;
  height: 40px;
  letter-spacing: .03em;
  width: 120px;
  cursor: pointer;
  transition: all 0.3s linear;
  margin: 0px 5px 0px 5px;
}

.ai-message-button-setting:hover {
  color: #FAFAFA;
  background-color: #F7931E;
  border-color: #7A7A7A;
}

.user-message {
  font-family: "ManropeLight";
  float: right;
  padding: 15px 10px;
  margin: 5px 0px 5px 200px;
  border-radius: 17px 17px 0px 17px;
  background: #FAFAFA;
  color: #535353;
  border: 1px solid #F0581A;
  max-width: 90%;
  text-align: left;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 0.95em;
  line-height: 1.6em;
}

.user-message-settings {
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}


.ai-input-box {
  font-family: "ManropeLight";
  font-size: 0.95em;
  line-height: 1.6em;
  justify-content: end;
  width: 45%;
  margin: 0px 0px 5px 0px;
  position: relative;
  display: flex;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid #E0E0E0;
  color: #535353;
  border-radius: 17px 17px 0px 17px;
  background-color: #FAFAFA;
  transition: all 0.2s linear;
}

.ai-input-box textarea {
  background-color: #FAFAFA;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 17px 0px 0px 17px;
  font-size: 1em;
  line-height: 1.6;
  padding-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
  height: 40px;
  resize: none;
  vertical-align: middle;
  font-family: "ManropeLight";
  font-size: 0.95em;
  line-height: 1.6em;
  color: #535353;
}

.ai-input-box textarea.placeholder {
  color: rgba(32, 32, 32, 0.4);
}

.ai-input-box textarea:focus {
  outline: none;
  border-color: #535353;
}

.ai-input-box button {
  width: 105px;
  padding-right: 10px;
  background-color: grey;
  border-radius: 0px 17px 0px 0px;
  border: none;
  cursor: pointer;
  cursor: hand;
  padding: 0px;
  transition: all 0.2s linear;
}

.ai-center.login button {
  width: 105px;
  background-color: #206FCB;
  border-radius: 12px;
  padding: 15px;
  border: none;
  cursor: pointer;
  cursor: hand;
  transition: all 0.2s linear;
}

.ai-center.login button:hover {
  background-color: #BCBCBC;
  transition: all 0.2s linear;
}

.ai-controls-box {
  height: 45px;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.ai-enter,
.ai-login-enter {
  color: #ffffff;
  font-family: "ManropeRegular";
  font-size: 1.3em;
  line-height: 1.6em;
}

.ai-enter.viewing-pdf,
.ai-login-enter.viewing-pdf {
  font-size: 1em;
}

.ai-input-box button:hover {
  background-color: #F0581A;
  transition: all 0.2s linear;
}

.ai-support,
.ai-save,
.ai-upload {
  width: 23px;
  height: 23px;
  padding: 3px;
  cursor: pointer;
  fill: #535353;
  transition: all 0.2s linear;
}

.ai-upload {
  display: flex;
}

.ai-copy {
  width: 21px;
  height: 20px;
  padding: 3px;
  cursor: pointer;
  fill: #535353;
  transition: all 0.2s linear;
}

.ai-settings {
  width: 20px;
  height: 20px;
  padding: 3px;
  stroke-width: 2px;
  cursor: pointer;
  fill: #535353;
  transition: all 0.2s linear;
}

.ai-settings:hover {
  transform: rotate(35deg);
  transition: all 0.2s linear;
}

.ai-upload:hover {
  transform: scale(0.9, 0.9);
}

.ai-settings:hover,
.ai-support:hover,
.ai-save:hover,
.ai-upload:hover {
  fill: #F0581A;
  transition: all 0.2s linear;
}

.ai-copy:hover {
  fill: #F0581A;
  transition: all 0.2s linear;
}

.contols-wrapper {
  position: relative;
  overflow: hidden;
  display: inline;
  width: 20px;
  height: 20px;
  padding: 3px;
  stroke-width: 2px;
  cursor: pointer;
}

.ai-upload {
  cursor: pointer;
}

input[type=file] {
  display: none;
  cursor: pointer;
}

.ai-uploaded-files {
  margin: 0;
  font-size: 0.8em;
}

.ai-uploaded-files p {
  margin: 0px;
}

.ai-center {
  height: 100%;
  width: 100%;
  margin: 12px 0px 12px 0px;
  overflow: auto;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  align-items: end;
}

.ai-center.login {
  height: 100%;
  width: 100%;
  margin: 12px 0px 12px 0px;
  overflow: auto;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ai-settings-window {
  width: 265px;
  height: 100%;
  margin-top: auto;
  transition: all 0.2s linear;
}

.ai-settings-inner {
  padding: 30px;
  background-color: #FAFAFA;
  overflow: auto;
  display: flex;
  transition: all 0.3s linear;
  flex-direction: column;
  margin-top: 20px;
  justify-content: start;
  align-items: left;
  position: absolute;
  right: -2000px;
  bottom: 0;
  margin-bottom: 145px;
  border-radius: 17px 17px 17px 17px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.ai-settings-main-heading {
  font-size: 1.4em;
  font-weight: 400;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  color: #535353;
  text-align: left;
}

.ai-settings-headings {
  font-size: 1.1em;
  font-weight: 300;
  margin: 0px;
  padding: 15px 0px 8px 0px;
  color: #535353;
  text-align: left;
}

.ai-settings-label {
  padding: 4px 0px 4px 0px;
}

.ai-settings-label .MuiFormControlLabel-label {
  padding-left: 6px;
  font-size: 0.9em;
  font-weight: 300;
  color: #535353;
  text-align: left;
}

.ai-settings-modelGroup {
  padding: 0px 0px 0px 10px;
}

#ai-message-options {
  margin: 8px 6px 0px 0px;
}

#ai-message-options-input {
  font-size: 0.9em;
  line-height: unset;
}

#ai-message-options .MuiInputBase-root {
  border-radius: 80px 80px 80px 80px !important;
}

#ai-message-options-select {
  font-size: 0.9em;
  height: unset;
  min-height: unset;
  padding: 13px 32px 13px 10px;
}

#ai-message-options-item {
  font-family: "ManropeLight";
  font-size: 0.9em;
  letter-spacing: 0.03em;
}

.MuiPaper-rounded {
  margin: 0px 15px;
}

.ai-message-feedback-btn { 
  margin: 8px 0px 0px 6px;
  border-radius: 80px 80px 80px 80px !important;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);
  font-family: "ManropeRegular";
  font-size: 0.9em;
  letter-spacing: 0.03em;
  padding: 13px 17px 13px 17px;
  color: #535353;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s linear;
}

.ai-message-feedback-btn:hover {
  border-color: #F0581A;
  color: #F0581A;
}

.ai-loading {
  float: left;
}

.feedback-modal {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, .5);
  border-radius: 17px;
  transition: all .4s;
  z-index: 15;
}

.feedback-modal.active {
  visibility: visible;
  opacity: 1;
}

.feedback-modal__content {
  border-radius: 17px;
  position: relative;
  width: 350px;
  min-height: 450px;
  background: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback-modal-heading {
  font-family: "ManropeLight";
  color: #535353;
  font-size: 1.4em;
  line-height: 1.6em;
  letter-spacing: 0.03em;
}

.feedback-modal-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ai-feedback-submit {
  background-color: #F7931E;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.3s linear;
}

.ai-feedback-submit:hover {
  background-color: #535353;
}

.ai-feedback-submit-btn {
  font-family: "ManropeBold";
  font-size: 1.3em;
  line-height: 1em;
}

.ai-feedback-cancel {
  background-color: #535353;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.3s linear;
  margin: 5px 0px 5px 0px;
}

.ai-feedback-cancel:hover {
  background-color: #F7931E;
}

.ai-feedback-cancel-btn {
  font-family: "ManropeBold";
  font-size: 1em;
  line-height: 1em;
}

.ai-feedback-field {
  margin: 8px 0px 8px 0px !important;
  width: 100%;
}

.ai-feedback-field.multiline .MuiInputBase-root,
.ai-feedback-field.multiline textarea {
  min-height: 150px !important;
}

#ai-feedback-textfield {
  font-family: "ManropeLight";
  font-size: 0.9em;
  letter-spacing: 0.03em;
}

#ai-feedback-options-input {
  line-height: unset;
  font-size: 1em;
}

#ai-feedback-options-select {
  font-size: 0.9em;
  height: unset;
  min-height: unset;
  padding: 13px 32px 13px 10px;
  text-align: left;
  margin-left: 5px;
}

#ai-feedback-options-item {
  font-family: "ManropeLight";
  font-size: 0.9em;
  letter-spacing: 0.03em;
}

.ai-pdf-menu {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: baseline;
  background-color:#206FCB;
}

.ai-pdf-menu-heading h1 {
  font-family: "ManropeRegular";
  color:#ffffff;
  font-size: 1.1em;
  letter-spacing: 0.03em;
  text-align: left;
}

.ai-pdf-settings {
  width: 22px;
  height: 22px;
  padding: 4px 6px 4px 6px;
  stroke-width: 30px;
  cursor: pointer;
  fill: #fafafa;
  transition: all 0.2s linear;
}

.ai-pdf-settings:hover {
  fill:#535353;
  transition: all 0.2s linear;
}

.ai-pdf-viewer {
  padding: 25px;
  overflow: scroll;
}

.ai-pdf-menu-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ai-pdf-page-counter {
  padding: 0px 0px 0px 8px;
}

.ai-pdf-page-counter span {
  font-family: "ManropeBold";
  color:#ffffff;
  font-size: 0.95em;
  letter-spacing: 0.03em;
  text-align: left;
}

.ai-sources-lines {
  margin-top: 0px;
  margin-bottom: 3px;
}

.ai-sources-lines li {
  font-size: 0.85em;
}

.bouncing-loader {
  display: flex;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 3px 4px 0px 4px;
  background-color: #0088CE;
  border-radius: 50%;
  opacity: 1;
  animation: bouncing-loader 1s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}