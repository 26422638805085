.loading-container {
    display: flex;
    flex-direction: column;
    height:100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.loading-heading {
    margin: 0px;
    font-family: "ManropeLight";
    font-size: 1.1em;
    color: #535353;
}

.box {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* left: 50%;
    top: 50%; */
    /* transform: translate(-50%, -50%); */
  }
  
  .column {
    width: 10%;
    height: 66%;
    background: #F29E49;
    border-radius: 15px;
  }
  
  .bead-container {
    width: 50%;
    height: 66%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .rod {
    width: 100%;
    height: 4%;
    background-color: #ED704A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .bead {
    width: 25%;
    height: 450%;
    border-radius: 50%;
    background-color: #535353;
  }
  
  #rod-one :nth-child(1) {
    animation: ease-in-out one-one 8s infinite;
  }
  #rod-one :nth-child(2) {
    animation: ease-in-out one-two 8s infinite;
  }
  #rod-one :nth-child(3) {
    animation: ease-in-out one-three 8s infinite;
  }
  
  #rod-two :nth-child(1) {
    animation: ease-in-out two-one 5s infinite;
  }
  #rod-two :nth-child(2) {
    animation: ease-in-out two-two 5s infinite;
  }
  
  #rod-three .bead {
    animation: ease-in-out three-one 6s infinite;
  }
  
  @keyframes one-one {
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(105%);
    }
    70% {
      transform: translateX(105%);
    }
    80% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes one-two {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(105%);
    }
    80% {
      transform: translateX(105%);
    }
    90% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes one-three {
    0% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(105%);
    }
    90% {
      transform: translateX(105%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes two-one {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(205%);
    }
    55% {
      transform: translateX(205%);
    }
    75% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes two-two {
    0% {
      transform: translateX(0);
    }
    5% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(205%);
    }
    75% {
      transform: translateX(205%);
    }
    95% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes three-one {
    0% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(305%);
    }
    60% {
      transform: translateX(305%);
    }
    90% {
      transform: translateX(0);
    }
  }