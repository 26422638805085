body.red {
    .abacus-navbar-main {
        background-color: #00747C;
    }

    .abacus-action-feedback-icon {
        color: #00747C;
    }

    .abacus-action-feedback-icon:hover {
        color: #535353
    }

    .abacus-action-settings-icon:hover {
        color: #00747C;
    }

    .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        background-color: #00747C;
        transition: all 0.3s linear;
    }

    .css-bexvx3-MuiSlider-root {
        color: #00747C;
    }

    ;

    .abacus-action-logo.user {
        background-color: #00747C;
    }

    .abacus-input-box textarea:focus {
        outline: none;

        .abacus-input-box {
            box-shadow: 0px 0px 16px 0px rgba(255, 174, 174, 0.8);
        }
    }

    .abacus-input-upload {
        color: #00747C
    }

    .bouncing-loader>div {
        background-color: #00747C;
    }

    .abacus-ai-right-sizer:hover {
        border-color: #00747C;
    }

    .ai-pdf-menu {
        background-color: #00747C;
    }

    .abacus-sources-list.active {
        border: 2px solid #00747C;

        .source-name {
            color: #00747C;
        }

        .source-button-container {
            .MuiInputBase-root {
                background-color: #00747C;
            }
        }
    }

    .abacus-launch-container {
        .heading {
            color: #00747C;
        }

        .abacus-launch-button {
            background-color: #00747C;
        }

        .abacus-launch-button:hover {
            color: #00747C;
            background-color: #BCBCBC;
        }
    }

    .abacus-newChat-container {
        .heading {
            color: #00747C;
        }

        .body {
            .start-tiles:hover {
                .h3 {
                    color: #00747C;
                }
            }
        }
    }

    .abacus-message-blob {
        .message-footer {
            .feedback {
                .icon.true:hover {
                    color: #535353;
                }

                .icon:hover {
                    color: #00747C;
                }
            }
        }

        .feedback-body {
            background-color: #BCBCBC;

            .feedback-actions {
                .feedback-btn {
                    background-color: #00747C;
                    color: #ffffff;

                    .MuiSvgIcon-root {
                        color: #ffffff;
                    }
                }

                .feedback-btn:hover {
                    background-color: #565656;
                    color: #ffffff;
                }
            }

            .input-box {
                .feedback-submit {
                    background-color: var(--nightModeBody);
                    border: 2px solid #00747C;
                    color: #00747C;
                }

                .feedback-submit:hover {
                    background-color: #00747C;
                    color: #ffffff;
                }
            }
        }
    }

    .abacus-input-container {
        .input-body {
            .source-list {
                color: #00747C;
            }
        }
    }

    .snacky-bar {
        background-color: #00747C;
        border-radius: 6px;

        .MuiSnackbarContent-root {
            background-color: #00747C;
            border-radius: 6px;
            box-shadow: none;
        }
    }

    .skeleton-loader-container {
        .skeleton-box {
            background-color: #00747C;
        }
    }
}