body.yellow {
    .abacus-navbar-main {
        background-color: #B7A6F6;
    }

    .abacus-action-feedback-icon {
        color: #B7A6F6;
    }

    .abacus-action-feedback-icon:hover {
        color: #535353
    }

    .abacus-action-feedback-icon.true {
        color:#535353;
    }

    .abacus-action-feedback-icon.true:hover {
        color:#535353;
    }

    .abacus-action-settings-icon:hover {
        color: #B7A6F6;
    }
    
    .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        background-color: #B7A6F6;
        transition: all 0.3s linear;
    }

    .css-bexvx3-MuiSlider-root {
        color: #B7A6F6;
    };

    .abacus-action-logo.user {
        background-color: #B7A6F6;
    }

    .abacus-input-box textarea:focus {
        outline: none;
        
        .abacus-input-box {
            box-shadow: 0px 0px 16px 0px rgba(255, 222, 172, 0.8);
        }
    }

    .abacus-input-upload {
        color: #B7A6F6
    }

    .bouncing-loader > div {
        background-color: #B7A6F6;
    }

    .abacus-ai-right-sizer:hover {
        border-color: #B7A6F6;
    }

    .ai-pdf-menu {
        background-color: #B7A6F6;
    }

    .abacus-sources-list.active {
        border: 2px solid #B7A6F6;

        .source-name {
            color: #B7A6F6;
        }

        .source-button-container {
            .MuiInputBase-root {
                background-color: #B7A6F6;
            }
        }
    }
    
    .abacus-launch-container {
        .heading {
            color: #B7A6F6;
        }

        .abacus-launch-button {
            background-color: #B7A6F6;
        }
    
        .abacus-launch-button:hover {
            color: #B7A6F6;
            background-color: #BCBCBC;
        }
    }

    .abacus-newChat-container {
        .heading {
            color: #B7A6F6;
        }

        .body {
            .start-tiles:hover {
                .h3{
                    color: #B7A6F6;
                }
            }
        } 
    }

    .abacus-message-blob {
        .message-footer {
            .feedback {
                .icon.true:hover {
                    color: #535353;
                }
                .icon:hover {
                    color: #B7A6F6;
                }
            }
        }
        .feedback-body {
            background-color: #BCBCBC;
            .feedback-actions {
                .feedback-btn {
                    background-color: #B7A6F6;
                    color: #ffffff;
                    .MuiSvgIcon-root {
                        color: #ffffff;
                    }
                }
                .feedback-btn:hover {
                    background-color: #565656;
                    color: #ffffff;
                }
            }
            .input-box {
                .feedback-submit {
                    background-color: var(--nightModeBody);
                    border: 2px solid #B7A6F6;
                    color: #B7A6F6;
                }
                .feedback-submit:hover {
                    background-color: #B7A6F6;
                    color: #ffffff;
                }
            }
        }
    }

    .abacus-input-container {
        .input-body {
            .source-list {
                color: #B7A6F6;
            }
        }
    }

    .snacky-bar {
        background-color: #B7A6F6;
        border-radius: 6px;

        .MuiSnackbarContent-root {
            background-color: #B7A6F6;
            border-radius: 6px;
            box-shadow: none;
        }
    }

    .skeleton-loader-container {
        .skeleton-box {
            background-color: #B7A6F6;
        }
    }
}