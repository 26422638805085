@media (max-width: 576px) {
    body {
        .abacus-ai-body {
            flex-direction:column;
        }

        .abacus-navbar-main {
            flex: 1;
            box-shadow: none;
            width: 100%;
            padding: 10px 0px 10px 0px;
            height: 35px;
            flex-direction: row;
            justify-content: center;

            .abacus-navbar-logo {
                display: none;
            }

            .abacus-navbar-multi-tool-body {
                display: none;
            }

            .abacus-navbar-options-body {
                display: none;
            }
        }

        .abacus-ai-container {
            height:100%;
            flex-shrink: 0;
            padding: 0px 20px 0px 20px;
            width: unset;

            .abacus-user-input-container {
                padding: 0px 0px 60px 0px;
                .abacus-input-header {
                    display: none;
                }
            }

        }


        .abacus-ai-history-body {
            padding: 20px 0px 0px 0px;

            .abacus-ai-window {
                min-width: unset;
            }
        }

        .abacus-ai-window-scroll {
            min-width: 10px;
            padding: unset;
        }
        

        .abacus-newChat-container {
    
            .body {
                flex-direction: column;

                .start-tiles {
                    width: unset;
                    margin: 7px 0px 7px 0px;
                }
            }

           
        }
    }
}

