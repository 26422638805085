body.medium {
    // .abacus-ai-history-body {
    //     height: 510px;
    // }

    .abacus-ai-history-body.true {
        height: 100%;
    }

    /**Navbar**/
    .abacus-navbar-logo {
        height: 10%;
    }

    .abacus-navbar-size-btn {
        height: 65px;
        width: 65px;
    }

    .abacus-navbar-size-btn .line {
        width: 28px;
    }

    .abacus-navbar-size-btn .line:nth-child(2) {
        width: 28px;
    }

    .abacus-navbar-multi-tool-body.is-active,
    .abacus-navbar-new-chat-btn.is-active,
    .abacus-navbar-main.is-active {
        width: 230px;
    }

    .abacus-navbar-new-chat-plus,
    .abacus-navbar-multi-tool-icon {
        height: 22px;
        width: 22px;
    }

    .abacus-navbar-new-chat-btn.is-active,
    .abacus-navbar-multi-tool-heading.is-active {
        font-size: 1em;
    }

    .abacus-navbar-options-body.is-active {
        width: 150px;
    }

    .abacus-navbar-options-icon {
        height: 20px;
        width: 20px;
    }

    .abacus-navbar-options-btn.is-active {
        font-size: 0.9em;
    }

    .abacus-navbar-multi-tool-content-headings.is-active {
        font-size: 0.95em;
    }

    .colorSelector {
        height: 20px;
        width: 40px;
    }

    .abacus-ui-size-slider.MuiSlider-root,
    .abacus-message-width-slider.MuiSlider-root {
        width: 160px !important;
        left: 18px;
        transition: all 0.3s linear;
    }

    /**AI Action Center**/
    .abacus-action-container {
        width: 65px;
    }

    .abacus-action-logo {
        width: 62px;
        height: 62px;
    }

    .abacus-action-logo.user p {
        font-size: 1.3em;
    }

    .abacus-action-container {
        .settings {
            .settings-btn {
                width: 62px;
                font-size: 0.75em;
            }
        }
    }

    /**AI Message**/
    .abacus-message,
    .abacus-message-blob,
    .abacus-user-message {
        font-size: 1em;
        flex-shrink: 65;
    }

    .abacus-input-upload {
        height: 22px;
        width: 22px;
    }

    .abacus-input-box textarea {
        font-size: 1em;
    }

    .abacus-sources-header {
        .abacus-message-sources-icon {
            width: 20px;
            height: 20px;
        }

        h2 {
            font-size: 1.4em;
        }
    }

    .abacus-sources-list.active {
        .source-name {
            font-size: 0.9em;
        }

        .source-lines {
            font-size: 0.85em;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            font-size: 0.9em;
        }
    }
}