.abacus-login-container{
    background-color: #F4F4F4;
    padding: 55px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;
    
    .abacus-login-header,
    .abacus-login-body {
        margin: 25px;
        transition: all 0.3s linear;
    }

    .abacus-login-btn {
        background-color: #206FCB;
        border: none;
        padding: 15px 25px 15px 25px;
        border-radius: 12px;
        cursor: pointer;
        transition: all 0.3s linear;
    }

    .abacus-login-btn:hover {
        background-color: #535353;
        transition: all 0.3s linear;
    }
}