body.bold {

    /**Navbar**/
    .abacus-navbar-new-chat-btn.is-active,
    .abacus-navbar-multi-tool-heading.is-active {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .abacus-navbar-options-btn.is-active {
        font-weight: 500;
        // font-family: ManropeExtraBold;
    }

    .abacus-ui-size-slider.MuiSlider-root {
        .MuiSlider-markLabel {
            font-weight: 500;
            font-family: ManropeExtraBold;
            transition: all 0.3s linear;
        }
    }

    .abacus-message-width-slider.MuiSlider-root {
        .MuiSlider-markLabel {
            font-weight: 500;
            font-family: ManropeExtraBold;
            transition: all 0.3s linear;
        }
    }

    .abacus-navbar-multi-tool-content-headings.is-active {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .abacus-navbar-options-btn.is-active {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    /**AI Action Center**/

    /**AI Message**/
    .abacus-message,
    .abacus-user-message {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .abacus-user-message p {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .abacus-input-box textarea {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .abacus-input-container {
        .abacus-uploaded-file {
            width: 120px;

            p {
                font-weight: 500;
                font-family: ManropeExtraBold;
            }
        }
    }

    .abacus-sources-header {
        h2 {
            font-weight: 500;
            font-family: ManropeExtraBold;
        }
    }

    .source-name {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .source-lines {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        font-weight: 500;
        font-family: ManropeExtraBold;
    }

    .abacus-launch-container {
        font-weight: 500;
        font-family: ManropeExtraBold;

        .heading {
            font-weight: 500;
            font-family: ManropeExtraBold;
        }

        p {
            font-weight: 500;
            font-family: ManropeExtraBold;
        }

        .abacus-launch-button {
            font-weight: 500;
            font-family: ManropeExtraBold;
        }
    }

    .abacus-newChat-container {
        font-weight: 500;
        font-family: ManropeExtraBold;

        .heading {
            font-weight: 500;
            font-family: ManropeExtraBold;
        }

        p {
            font-weight: 500;
            font-family: ManropeExtraBold;
        }

        .body {
            .start-tiles {
                font-weight: 500;
                font-family: ManropeExtraBold;

                p {
                    font-weight: 500;
                    font-family: ManropeExtraBold;
                }
            }
        }
    }
}