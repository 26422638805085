@property --backgroundGradPrimary {
    syntax: '<color>';
    initial-value: #CDF0FF;
    inherits: false;
}

@property --backgroundGradSec {
    syntax: '<color>';
    initial-value: #2B5AB6;
    inherits: false;
}

@property --nightModeBody {
    syntax: '<color>';
    initial-value: #FFFFFF;
    inherits: false;
}

.abacus-navbar-main {
    width: 45px;
    position: relative;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin: 0px 15px 0px 0px;
    z-index: 5;
    background-color: #2B5AB6;
    transition: all 0.3s linear;
}

.abacus-navbar-main.is-active {
    width: 250px;
}

.nightMode {
    --nightModeBody: #1C1B1F;
    transition: all 0.3s linear;
}

.nightModeBody {
    --nightModeBody: #1C1B1F;
    transition: all 0.3s linear;
}

.abacus-navbar-logo {
    height: 10%;
    transition: all 0.3s linear
}

.abacus-navbar-size-btn {
    height: 65px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s linear
}

.abacus-navbar-size-btn .line {
    width: 28px;
    height: 3px;
    border-radius: 1px;
    background-color: #ffffff;
    display: block;
    margin: 7px auto;
    cursor: pointer;
    transition: all 0.3s linear
}

.abacus-navbar-size-btn .line:nth-child(2) {
    background-color: #ffffff;
    width: 28px;
    transition: all 0.3s linear
}

.abacus-navbar-hamburger.is-active .line:nth-child(2) {
    opacity: 0;
}

.abacus-navbar-hamburger:hover .line {
    background-color: #AFAFAF;
}

.abacus-navbar-hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(12px) rotate(45deg);
    -ms-transform: translateY(12px) rotate(45deg);
    -o-transform: translateY(12px) rotate(45deg);
    transform: translateY(12px) rotate(45deg);
}

.abacus-navbar-hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    -o-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
}

.abacus-navbar-options-body>button:hover {
    cursor: pointer;
}

.abacus-navbar-multi-tool-body {
    margin: 5px 0px 0px 0px;
    max-height: 75px;
    width: 50px;
    text-align: center;
    font-size: 1em;
    position: relative;
    display: flex;
    flex-direction: row;
    font-family: "ManropeRegular";
    background-color: var(--nightModeBody);
    border-radius: 12px;
    z-index: 12;
    transition: all 0.3s linear;
}

.abacus-navbar-multi-tool-body.is-active {
    max-height: 420px;
    height: 100%;
    width: 250px;
    transition: all 0.3s linear;
}

.abacus-navbar-multi-tool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px 15px 0px;
    box-shadow: none;
    border: none;
    margin: 0px 0px 0px 0px;
    transition: all 0.3s linear;
}

.abacus-navbar-multi-tool-container.profile-settings {
    font-size: 1em;
    width: 100%;
    margin: 0px 0px 0px 0px;
    transition: all 0.3s linear;

    .abacus-navbar-multi-tool-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 25px;
        transition: all 0.3s linear;
    }
}

.abacus-navbar-multi-tool-container.chat-history {
    width: 100%;
    padding: 15px;
    transition: all 0.3s linear;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 25px;
        transition: all 0.3s linear;
    }

    .body {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 0%;
        transition: all 0.3s linear;

        .history-accordion {
            width: 0%;
            border: none;
            border-radius: 0px;
            box-shadow: unset;
            margin: 0px;
            font-family: "ManropeRegular";
            background-color: transparent;
            transition: all 0.3s linear;

            .history-accordion-summary {
                width: 0%;
                padding: 0px;
                background-color: transparent;
                transition: all 0.3s linear;

                .MuiAccordionSummary-expandIconWrapper {
                    .MuiSvgIcon-root {
                        height: 0px;
                        width: 0px;
                        transition: all 0.3s linear;
                    }

                    .MuiSvgIcon-root:hover {
                        color: #2A3A57;
                        transition: all 0.3s linear;
                    }
                }

                .history-accordion-label {
                    font-family: "ManropeRegular";
                    font-size: 0em;
                    color: #565656;
                    transition: all 0.3s linear;
                }

                
            }
        }

        .history-accordion-details {
            padding: 0px;
            transition: all 0.3s linear;

            .history-accordion-details-title {
                font-size: 0em;
                transition: all 0.3s linear;
            }

            
        }
    }

    .body.true {
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        .history-accordion.true {
            width: 100%;
            border: none;
            border-radius: 0px;
            box-shadow: unset;
            margin: 5px;
            font-family: "ManropeRegular";

            .history-accordion-summary.true {
                width: 100%;
                font-family: "ManropeRegular";
                padding: 0px 20px 0px 5px;

                .history-accordion-label.true {
                    font-family: "ManropeRegular";
                    font-size: 1em;
                    color: #565656;
                }

                .history-accordion-label.true:hover{
                    color: #2A3A57;
                }
            }
        }

        .history-accordion-details.true {
            padding: 5px 0px 5px 10px;

            .history-accordion-details-title.true {
                font-family: "ManropeRegular";
                font-size: 0.95em;
                text-align: left;
                color: #565656;
                cursor: pointer;
            }

            .history-accordion-details-title.true:hover {
                color: #2A3A57;
            }
        }
    }
}

.abacus-navbar-multi-tool-container.profile-settings {
    padding: 15px;
}

.abacus-navbar-multi-tool-icon {
    margin: 0px 0px 0px 0px;
    color: #565656;
    height: 22px;
    width: 22px;
    transition: all 0.3s linear;
}

.svg-inline--fa .fa-secondary {
    opacity: 1;
}

.abacus-navbar-multi-tool-icon.is-active {
    margin: 0px 0px 0px 15px;
    transition: all 0.3s linear;
}

.abacus-navbar-multi-tool-heading {
    font-size: 0px;
    margin: 0px;
    padding: 0px;
    color: #565656;
    letter-spacing: 0.05em;
    font-weight: unset;
    text-align: left;
    font-family: "ManropeRegular";
    transition: all 0.3s linear;
}

.abacus-navbar-multi-tool-heading.is-active {
    font-size: 1em;
    width: 100%;
    margin: 0px 0px 0px 15px;
    transition: all 0.3s linear;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 25px;
        transition: all 0.3s linear;
    }
}

.abacus-navbar-multi-tool-content {
    margin: 15px 0px 15px 0px;
    font-family: "ManropeRegular";
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 0.3s linear;
}

.abacus-navbar-multi-tool-content-headings {
    font-weight: 300;
    text-align: center;
    font-size: 0em;
    color: #565656;
    letter-spacing: 0.05em;
    font-family: "ManropeRegular";
    padding: 0px;
    transition: all 0.3s linear;
}

.abacus-navbar-multi-tool-content-colorSelector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 12px 0px 0px 0px;
    transition: all 0.3s linear;
}

.colorSelector {
    height: 20px;
    width: 40px;
    border-radius: 32px;
    margin: 5px;
    transition: all 0.3s linear;
}

.colorSelector.blue {
    background-color: #2A3A57;
}

.colorSelector.blue:hover,
.colorSelector.blue.true {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #2A3A57;
    animation: pulsing 3s linear infinite var(--s, 0s);
}

.colorSelector.blue::after,
.colorSelector.blue::before {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #2A3A57;
}

.colorSelector.blue::before {
    --s: 1s;
}

.colorSelector.blue::after {
    --s: 2s;
}

.colorSelector.red {
    background-color: #00747C;
}

.colorSelector.red:hover,
.colorSelector.red.true {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #00747C;
    animation: pulsing 2s linear infinite var(--s, 0s);
}


.colorSelector.red::after,
.colorSelector.red::before {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #00747C;
}

.colorSelector.red::before {
    --s: 1s;
}

.colorSelector.red::after {
    --s: 2s;
}

.colorSelector.green {
    background-color: #B6C8CC;
}

.colorSelector.green:hover,
.colorSelector.green.true {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #B6C8CC;
    animation: pulsing 2s linear infinite var(--s, 0s);
}


.colorSelector.green::after,
.colorSelector.green::before {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #B6C8CC;
}

.colorSelector.green::before {
    --s: 1s;
}

.colorSelector.green::after {
    --s: 2s;
}

.colorSelector.yellow {
    background-color: #B7A6F6;
}

.colorSelector.yellow:hover,
.colorSelector.yellow.true {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #B7A6F6;
    animation: pulsing 2s linear infinite var(--s, 0s);
}


.colorSelector.yellow::after,
.colorSelector.yellow::before {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 2;
    border-radius: 32px;
    box-shadow: 0 0 0 0 #B7A6F6;
}

.colorSelector.yellow::before {
    --s: 1s;
}

.colorSelector.yellow::after {
    --s: 2s;
}


@keyframes pulsing {
    to {
        box-shadow: 0 0 0 0.5rem #0000;
    }
}

.abacus-navbar-options-body {
    border-top: 2px solid #ffffff;
    align-items: center;
    bottom: 0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-family: "ManropeRegular";
    justify-self: center;
    letter-spacing: 0em;
    margin: 0px 0px 15px 0px;
    padding: 20px 5px 20px 5px;
    position: absolute;
    text-align: center;
    width: 40px;
    transition: all 0.3s linear;
}

.abacus-navbar-options-body-btn {
    padding: 6px 0px 6px 0px;
    display: flex;
    /* margin: 0px 0px 0px 28.5px; */
    flex-direction: row;
    transition: all 0.3s linear;
    cursor: pointer;
}

.abacus-navbar-options-body-btn.is-active {
    margin: 0px 0px 0px 15px;
    transition: all 0.3s linear;
}

.abacus-navbar-options-body-btn:hover,
.abacus-navbar-options-body-btn.is-active:hover {

    .abacus-navbar-options-icon,
    .abacus-navbar-options-icon.is-active {
        color: #AFAFAF;
    }

    .abacus-navbar-options-btn.is-active {
        color: #AFAFAF;
    }
}

.abacus-navbar-options-icon {
    margin: 0px 0px 0px 0px;
    color: #ffffff;
    transition: all 0.3s linear;
}

.abacus-navbar-options-btn {
    width: 0px;
    font-size: 0px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    color: #ffffff;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s linear;
}

.abacus-navbar-options-btn.is-active {
    width: 80px;
    height: 15px;
    font-size: 0.9em;
    letter-spacing: 0.05em;
    padding: 0px;
    transition: all 0.3s linear;
}

.abacus-navbar-options-body-btn.profile.true {

    .abacus-navbar-options-icon,
    .abacus-navbar-options-icon.is-active {
        color: #AFAFAF;
    }

    .abacus-navbar-options-btn.is-active {
        color: #AFAFAF;
    }
}

.abacus-navbar-options-icon.is-active {
    margin: 0px 10px 0px 0px;
}

.abacus-nightMode-label {
    padding: 4px 0px 4px 16px;
}

.abacus-nightMode-label .MuiFormControlLabel-label {
    padding-left: 6px;
    font-size: 0.9em;
    font-weight: 300;
    color: #535353;
    text-align: left;
}

.abacus-font-weight-slider.MuiSlider-root {
    width: 65px !important;

    .css-1ucwjgd-MuiSlider-markLabel {
        font-weight: 400;
        font-family: ManropeLight;
        transition: all 0.3s linear;
    }

    .css-1bvr8oc-MuiSlider-markLabel {
        font-weight: 500;
        font-family: ManropeExtraBold;
        transition: all 0.3s linear;
    }
}

.abacus-ui-size-slider.MuiSlider-root {
    span[data-index="0"] {
        font-size: 0.8em;
        transition: all 0.3s linear;
    }

    span[data-index="1"] {
        font-size: 0.9em;
        transition: all 0.3s linear;
    }

    span[data-index="2"] {
        font-size: 1em;
        transition: all 0.3s linear;
    }
}

.abacus-message-width-slider.MuiSlider-root {
    span[data-index="*"] {
        font-size: 0.8em;
        transition: all 0.3s linear;
    }
}

.abacus-navbar-multi-tool-content-uiChanger {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    padding: 12px 0px 12px 0px;
}

.abacus-navbar-multi-tool-content-nightMode,
.abacus-navbar-multi-tool-content-fontWeight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 5px 0px 5px;
}

.abacus-navbar-multi-tool-content-nightMode {
    .abacus-nightMode-modelGroup {
        padding: 4px 0px 4px 0px;
    }

    .css-j204z7-MuiFormControlLabel-root {
        margin: 0px;
    }
}