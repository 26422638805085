body.nightModeBody {
    .abacus-newChat-container {
        background-color: #35323D;

        .body {
            .start-tiles {
                background-color: #565656;

                h3 {
                    color: #ffffff;
                    margin: 0px 0px 15px 0px;
                }

                p {
                    color: #ffffff;
                }
            }

            .start-tiles:hover {
                background-color: #BCBCBC;

                h3 {
                    color: #565656;
                }

                p {
                    color: #565656;
                }
            }
        }
    }
}