body.nightModeBody {
    .abacus-input-header {
        .abacus-input-new-chat-btn {
            background-color: #3C3A43;
            border: 2px solid #ffffff;
            color: #ffffff;
        }

        .abacus-input-new-chat-btn:hover {
            background-color: #565656;
            color: #ffffff;
            border: 2px solid #565656;

            .abacus-input-new-chat-plus,
            .abacus-input-new-chat-hammer {
                color: #ffffff;
            }
        }

        #abacus-souce-options {
            #abacus-souce-options-input {
                color: #ffffff;
                
            }

            #abacus-souce-options-select {
                color: #ffffff;
                background-color: #3C3A43;
            }

            .MuiSelect-icon {
                color: #ffffff;
            }

            .MuiOutlinedInput-notchedOutline {
                border: 2px solid #ffffff;
            }
        }
    }

    .abacus-input-new-chat-btn:hover {
        background-color: #3C3A43;
        color: #fafafa;

        .abacus-input-new-chat-plus,
        .abacus-input-new-chat-hammer {
            color: #fafafa;
        }
    }

    .abacus-user-message {
        background: #3C3A43;
        color: #fafafa;
    }

    .abacus-input-container,
    .abacus-input-box {
        background-color: #4B465A;

        .abacus-input-enter-chat-btn {
            background-color: #3C3A43;
            border: 3px solid #ffffff;
            color: #ffffff;
        }

        .abacus-input-enter-chat-btn:hover {
            background-color: #565656;
            color: #ffffff;
            border: 3px solid #565656;
        }

    }

    .abacus-input-box textarea {
        color: #fafafa;
    }

    .abacus-input-upload:hover {
        color: #fafafa
    }

    .abacus-input-box textarea::placeholder {
        color: #fafafa
    }
}