body.large {
    .abacus-ai-history-body.true {
        height: 100%;
    }

    /**Navbar**/
    .abacus-navbar-logo {
        height: 12%;
    }

    .abacus-navbar-size-btn {
        height: 80px;
        width: 80px;
    }

    .abacus-navbar-size-btn .line {
        width: 32px;
    }

    .abacus-navbar-size-btn .line:nth-child(2) {
        width: 36px;
    }

    .abacus-navbar-multi-tool-body.is-active,
    .abacus-navbar-new-chat-btn.is-active,
    .abacus-navbar-main.is-active {
        width: 260px;
    }

    .abacus-navbar-new-chat-plus,
    .abacus-navbar-multi-tool-icon {
        height: 25px;
        width: 25px;
    }

    .abacus-navbar-new-chat-btn.is-active,
    .abacus-navbar-multi-tool-heading.is-active {
        font-size: 1.15em;
    }

    .abacus-navbar-options-body.is-active {
        width: 170px;
    }

    .abacus-navbar-options-icon {
        height: 23px;
        width: 23px;
    }

    .abacus-navbar-options-btn.is-active {
        font-size: 1.05em;
    }

    .abacus-navbar-multi-tool-content-headings.is-active {
        font-size: 1.1em;
    }

    .colorSelector {
        height: 25px;
        width: 45px;
    }

    .abacus-ui-size-slider.MuiSlider-root,
    .abacus-message-width-slider.MuiSlider-root {
        width: 185px !important;
        left: 18px;
        transition: all 0.3s linear;
    }

    .abacus-navbar-options-body-btn.is-active {
        margin: 0px;
    }

    /**AI Action Center**/
    .abacus-action-container {
        width: 72px;
    }

    .abacus-action-logo {
        width: 54px;
        height: 54px;
    }

    .abacus-action-logo.user p {
        font-size: 1.4em;
    }

    .abacus-action-feedback-icon {
        width: 25px;
        height: 25px;
    }

    .abacus-action-settings-icon {
        width: 25px;
        height: 25px;
    }

    .abacus-action-settings-btn {
        width: 54px;
        font-size: 0.85em;
    }

    .abacus-message,
    .abacus-message-blob,
    .abacus-user-message {
        font-size: 1.15em;
        flex-shrink: 50;
    }

    .abacus-input-box textarea {
        font-size: 1.15em;
    }

    .abacus-input-upload {
        height: 25px;
        width: 25px;
    }

    .abacus-input-container {
        .abacus-uploaded-file {
            width: 180px;

            p {
                font-size: 1.15em
            }
        }
    }

    .abacus-sources-header {
        .abacus-message-sources-icon {
            width: 23px;
            height: 23px;
        }

        h2 {
            font-size: 1.6em;
        }
    }

    .source-name {
        font-size: 1em;
    }

    .source-lines {
        font-size: 0.95em;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 1.05em;
    }

    .abacus-launch-container {
        .content {
            padding: 28px;
            transition: all 0.3s linear;
        }
    
        .heading {
            font-size: 2.4em;
        }
    
        p {
            font-size: 1.1em;
        }
    
        .abacus-launch-button {
            padding: 18px 28px 18px 28px;
            font-size: 1.1em;
        }
    }

    .abacus-newChat-container {
        .content {
            padding: 28px;
        }

        .heading {
            font-size: 2.4em;
        }

        p {
            font-size: 1.1em;
        }

        .body {
            display: flex;
            flex-direction: row;

            .start-tiles {
                padding: 28px;
                p {
                    font-size: 1.1em;
                }
            }
        }
    }
}