@font-face {
  font-family: 'ManropeBold';
  src: local('ManropeBold'), url("../assets/fonts/Manrope-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'ManropeExtraBold';
  src: local('ManropeExtraBold'), url("../assets/fonts/Manrope-ExtraBold.ttf") format('truetype');
}
@font-face {
  font-family: 'ManropeExtraLight';
  src: local('ManropeExtraLight'), url("../assets/fonts/Manrope-ExtraLight.ttf") format('truetype');
}
@font-face {
  font-family: 'ManropeLight';
  src: local('ManropeLight'), url("../assets/fonts/Manrope-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'ManropeMedium';
  src: local('ManropeMedium'), url("../assets/fonts/Manrope-Medium.ttf") format('truetype');
}
@font-face {
  font-family: 'ManropeRegular';
  src: local('ManropeRegular'), url("../assets/fonts/Manrope-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'ManropeSemiBold';
  src: local('ManropeSemiBold'), url("../assets/fonts/Manrope-SemiBold.ttf") format('truetype');
}

html, body {
  margin: 0;
  font-family: "ManropeLight", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--nightModeBody);
  height: 100%;
  overflow: hidden;
  transition: all 0.3s linear;
}

#root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}