body {
    .abacus-ai-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 0px 0px 0px;
        z-index: 5;
        transition: all 0.3s linear;
    }

    .abacus-ai-history-body {
        width: 100%;
        display: flex;
        overflow: hidden;
        flex-direction: row;
        // align-items: center;
        justify-content: center;
        padding: 0px 0px 20px 0px;
        transition-delay: 10s;
        transition: all 0.3s linear;
    }

    .abacus-ai-history-body.true {
        height: 100%;
    }

    .abacus-ai-sizer-container {
        display: flex;
        align-items: center;
    }

    .abacus-ai-right-sizer {
        transition: all 0.3s linear;
        border-left: 3px solid #BCBCBC;
        border-radius: 1px;
        border-right: 3px solid #BCBCBC;
        height: 40px;
        width: 4px;
        margin: auto;
        cursor: col-resize;
    }

    .abacus-ai-right-sizer {
        margin: 0px 15px 0px 0px;
    }

    .abacus-ai-window {
        // height: 100%;
        width: 100%;
        min-width: 350px;
        display: flex;
        flex-direction: column;
    }

    .abacus-ai-window-scroll {
        padding: 20px 20px 0px 15px;
        width: 100%;
        min-width: 350px;
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: row;
    }

    .abacus-message-container {
        display: flex;
        flex-direction: row;
        justify-content: start;
        margin: 0px 0px 20px 0px;
        transition: all 0.3s linear;
    }

    .abacus-message-blob {
        display: flex;
        flex-direction: column;
        transition: all 0.3s linear;
        width: calc(100% - 80px);

        .abacus-message {
            color: #535353;
            float: left;
            font-family: "ManropeLight";
            font-size: 0.9em;
            width: 100%;
            line-height: 1.6em;
            overflow: hidden;
            text-align: left;
            word-wrap: break-word;
            flex-shrink: 51;
            transition: all 0.3s linear;
        }

        .abacus-message p {
            margin: 0px;
            padding: 0px 0px 10px 0px;
            text-align: left;
        }

        .message-footer {
            margin: 5px 0px 0px 0px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .settings {
                font-family: "ManropeRegular";
                cursor: pointer;
                margin: 0px 0px 0px 0px;

                .settings-btn {
                    text-align: center;
                    margin: 2px 6px 2px 0px;
                    background-color: var(--nightModeBody);
                    box-shadow: none;
                    border: 2px solid #565656;
                    color: #565656;
                    border-radius: 6px;
                    font-size: 0.8em;
                    letter-spacing: 0.05em;
                    padding: 5px 10px 5px 10px;
                    font-family: "ManropeRegular";
                    z-index: 10;
                    cursor: pointer;
                    transition: all 0.3s linear;
                }

                .settings-btn:hover {
                    background-color: #565656;
                    color: #ffffff;
                    transition: all 0.3s linear;
                }
            }

            .feedback {
                cursor: pointer;
                margin: 0px 0px 0px 0px;
                display: flex;
                flex-direction: row;

                .icon {
                    height: 25px;
                    width: 25px;
                    color: #565656;
                    padding: 2px 2px 2px 2px;
                    transition: all 0.3s linear;
                }

                .icon.true {
                    cursor: default;
                    color: #535353;
                }

                .icon.true:hover {
                    cursor: default;
                    color: #535353;
                }

                .icon.thumbsDown {
                    transform: scaleX(-1);
                }

                .icon:hover {
                    color: #206FCB;
                }
            }
        }

        .feedback-body {
            text-align: left;
            background-color: #BCBCBC;
            border-radius: 12px;
            padding: 15px;
            margin: 15px 0px 15px 0px;
            position: relative;
            flex-shrink: 65;

            h3 {
                font-family: "ManropeLight";
                font-weight: 600;
                font-size: 1.2em;
                letter-spacing: 0.05em;
            }

            .close-icon {
                position: absolute;
                left: 94%;
                top: 15px;
                height: 25px;
                cursor: pointer;
            }

            .feedback-actions {
                .feedback-actions-group {
                    display: flex;
                    flex-direction: row;
                }

                .feedback-btn {
                    text-align: center;
                    margin: 15px 10px 0px 0px;
                    background-color: #206FCB;
                    box-shadow: none;
                    border: none;
                    color: #ffffff;
                    border-radius: 6px;
                    padding: 0px 15px 0px 0px;
                    z-index: 10;
                    cursor: pointer;
                    transition: all 0.3s linear;

                    .MuiTypography-root {
                        font-size: 0.8em;
                        letter-spacing: 0.05em;
                        font-family: "ManropeRegular";
                    }

                    .MuiSvgIcon-root {
                        height: 20px;
                        color: #ffffff;
                    }
                }

                .feedback-btn:hover {
                    background-color: #565656;
                    color: #ffffff;
                    transition: all 0.3s linear;
                }
            }

            .input-box {
                display: flex;
                flex-direction: column;
                margin: 15px 0px 0px 0px;
                border-radius: 6px;

                textarea {
                    font-family: "ManropeRegular";
                    color: #535353;
                    border-radius: 6px;
                    background-color: #F4F4F4;
                    border: none;
                    line-height: 1.6em;
                    letter-spacing: 0.02em;
                    resize: none;
                    max-height: 300px;
                    padding: 10px 0px 10px 10px;
                    font-size: 0.9em;
                    transition: font-size 0.3s linear, background-color 0.3s linear;
                    width: 95%;
                }

                textarea::placeholder {
                    color: #535353;
                }

                textarea:focus {
                    outline: none;
                }

                .feedback-submit {
                    padding: 10px 15px 10px 15px;
                    margin: 10px 0px 0px 0px;
                    background-color: var(--nightModeBody);
                    box-shadow: none;
                    border: 2px solid #565656;
                    color: #565656;
                    border-radius: 6px;
                    font-size: 0.95em;
                    letter-spacing: 0.05em;
                    text-align: center;
                    width: 90px;
                    font-family: "ManropeRegular";
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    cursor: pointer;
                    transition: all 0.3s linear;
                }

                .feedback-submit:hover {
                    background-color: #565656;
                    color: #ffffff;
                    transition: all 0.3s linear;
                }
            }
        }
    }

    .abacus-action-container {
        width: 62px;
        margin: 0px 10px 0px 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        transition: all 0.3s linear;

        .settings {
            cursor: pointer;
            margin: 0px 0px 0px 0px;

            .settings-btn {
                width: 62px;
                text-align: center;
                margin: 4px 0px 4px 0px;
                background-color: var(--nightModeBody);
                box-shadow: none;
                border: 1px solid #565656;
                color: #565656;
                border-radius: 6px;
                font-size: 0.75em;
                letter-spacing: 0.05em;
                padding: 5px 10px 5px 10px;
                font-family: "ManropeRegular";
                z-index: 10;
                cursor: pointer;
                transition: all 0.3s linear;
            }

            .settings-btn:hover {
                background-color: #565656;
                color: #ffffff;
                transition: all 0.3s linear;
            }
        }
    }

    .abacus-action-logo {
        width: 50px;
        height: 50px;
        background-color: #BCBCBC;
        border-radius: 6px;
        margin: 0px 0px 6px 0px;
        transition: all 0.3s linear;
    }

    .abacus-sources {
        background: #DBDBDB;
        border-radius: 12px;
        height: 60px;
        color: #000000;
        font-family: "ManropeLight";
        font-size: 0.95em;
        line-height: 1.6em;
        margin: 15px 0px 15px 0px;
        text-align: left;
        word-wrap: break-word;
        transition: all 0.3s linear;
        overflow: hidden;
    }

    .abacus-sources.active {
        height: 346px;
        transition: all 0.3s linear;
    }

    .abacus-sources-header {
        display: flex;
        padding: 20px 20px 0px 20px;
        flex-direction: row;
        align-items: center;
        transition: all 0.3s linear;

        .heading {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: all 0.3s linear;

            .abacus-message-sources-icon {
                height: 20px;
                width: 20px;
                padding: 0px 10px 0px 0px;
                transition: all 0.3s linear;
            }

            h2 {
                font-size: 1.4em;
                font-family: "ManropeRegular";
                font-weight: 300;
                padding: 0px;
                margin: 0px;
                transition: all 0.3s linear;
            }
        }

        .dropdown {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            transition: all 0.3s linear;

            .icon {
                height: 25px;
                cursor: pointer;
                transition: all 0.3s linear;
            }

            .icon.active {
                transform: rotate(180deg);
            }
        }
    }

    .abacus-sources-list-group {
        overflow-x: auto;
        flex-wrap: nowrap;
        display: flex;
        width: 100%;
        flex-direction: row;
        padding: 15px 0px 15px 0px;
        transition: all 0.3s linear;
    }

    .abacus-sources-list.active {
        flex: 0 0 auto;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px;
        border-radius: 12px;
        border: 2px solid #206FCB;
        width: 150px;
        margin: 0px 7px 0px 7px;
        transition: all 0.3s linear;

        .source-name {
            font-size: 0.9em;
            color: #206FCB;
            margin: 0px 0px 4px 0px;
            font-family: "ManropeBold";
            line-break: anywhere;
            transition: all 0.3s linear;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            line-clamp: 5;
            overflow: hidden;
        }

        .source-lines {
            font-size: 0.85em;
            margin: 0px 0px 0px 10px;
            font-family: "ManropeRegular";
            line-break: anywhere;
            transition: all 0.3s linear;
        }

        .source-button-container {
            width: 100%;
            display: flex;
            transition: all 0.3s linear;

            .MuiFormLabel-root {
                margin: 0px;
                transition: all 0.3s linear;
            }

            .MuiInputBase-root {
                background-color: #206FCB;
                color: #ffffff;
                border-radius: 12px;
                border: none;
                transition: all 0.3s linear;
            }

            #abacus-message-options-input {
                color: #ffffff;
                transition: all 0.3s linear;
            }

            .MuiInputBase-root.Mui-focused {
                color: #000000 !important;
                top: -8px;
                transition: all 0.3s linear;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
                transition: all 0.3s linear;
            }


            .abacus-message-options-input {
                color: #ffffff !important;
                transition: all 0.3s linear;
            }
        }
    }

    .abacus-sources-list.active:first-child {
        margin: 0px 7px 0px 15px;
    }

    .abacus-sources-list.active:last-child {
        margin: 0px 15px 0px 7px;
    }

    .skeleton-loader-container {
        padding: 0px;
        display: flex;
        align-items: left;
        justify-content: center;
        flex-direction: column;

        .skeleton-box {
            display: inline-block;
            border-radius: 6px;
            height: 1em;
            position: relative;
            overflow: hidden;
            margin: 0px 0px 6px 0px;
            width: 40%;
            background-color: #00747C;

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(90deg,
                        rgba(#fff, 0) 0,
                        rgba(#fff, 0.2) 20%,
                        rgba(#fff, 0.5) 60%,
                        rgba(#fff, 0));
                animation: shimmer 3s infinite;
                content: '';
            }

            @keyframes shimmer {
                100% {
                    transform: translateX(100%);
                }
            }
        }

        .two {
            width: 30%;
        }

        .three {
            width: 60%;
        }
    }
}