body.nightModeBody {

    .abacus-navbar-new-chat-btn {
        background-color: #1C1B1F;
        color: #fafafa
    }

    .abacus-navbar-multi-tool-body {
        background-color: #1C1B1F;
    }

    .abacus-navbar-multi-tool-container {
        color: #fafafa
    }

    .abacus-navbar-multi-tool-icon,
    .abacus-navbar-multi-tool-heading,
    .abacus-navbar-multi-tool-content-headings {
        color: #fafafa
    }

    .css-pqfzmt-MuiSwitch-root .MuiSwitch-thumb {
        color: #1C1B1F;
    }

    .MuiSlider-markLabel {
        color: #fafafa
    }
}