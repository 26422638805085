.abacus-launch-container {
    background-color: #F4F4F4;
    width: 100%;
    max-width: 750px;
    border-radius: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s linear;

    .content {
        padding: 25px;
        transition: all 0.3s linear;
    }

    .heading {
        color: #206FCB;
        font-size: 2em;
        font-family: "ManropeRegular";
        font-weight: 600;
        letter-spacing: 0.02em;
        padding: 0px 0px 15px 0px;
        transition: all 0.3s linear;
    }

    p {
        font-family: "ManropeLight";
        padding: 0px 0px 15px 0px;
        line-height: 1.5em;
        transition: all 0.3s linear;
    }

    .abacus-launch-button {
        padding: 15px 25px 15px 25px;
        border: none;
        border-radius: 12px;
        color: #ffffff;
        background-color: #206FCB;
        font-size: 1em;
        font-family: "ManropeRegular";
        letter-spacing: 0.03em;
        transition: all 0.3s linear;
        margin: 0px 0px 25px 0px;
        cursor: pointer;
    }

    .abacus-launch-button:hover {
        color: #206FCB;
        background-color: #BCBCBC;
    }
}

.abacus-launch-container.true {
    transition: all 0.3s linear;
    opacity: 0;
}