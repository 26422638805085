body.nightModeBody {
    .abacus-navbar-new-chat-btn:hover {
        background-color: #35323D;
        color: #ffffff;

        .abacus-navbar-new-chat-plus {
            color: #ffffff;
        }
    }

    .abacus-ai-left-sizer,
    .abacus-ai-right-sizer {
        border-left: 3px solid #ffffff;
        border-right: 3px solid #ffffff;
    }

    .abacus-action-settings-icon {
        color: #fafafa;
    }

    .abacus-action-settings-icon:hover {
        color: #206FCB;
    }

    .abacus-message {
        color: #fafafa;
    }

    .abacus-action-feedback-icon:hover {
        color: #ffffff;
    }

    .abacus-action-container {
        .settings {
            .settings-btn {
                background-color: #3C3A43;
                border: 1px solid #ffffff;
                color: #ffffff;
            }

            .settings-btn:hover {
                background-color: #565656;
                color: #ffffff;
                border: 1px solid #565656;
                transition: all 0.3s linear;
            }
        }
    }

    .abacus-message-blob {
        .message-footer {
            .feedback {
                .icon {
                    color: #fafafa;
                }
            }

            .settings-btn {
                background-color: #3C3A43;
                border: 2px solid #ffffff;
                color: #ffffff;
            }

            .settings-btn:hover {
                background-color: #565656;
                color: #ffffff;
                border: 2px solid #565656;
                transition: all 0.3s linear;
            }
        }

        .feedback-body {
            background-color: #BCBCBC;

            .feedback-actions {
                .feedback-btn {
                    background-color: #C9283F;
                    color: #ffffff;

                    .MuiSvgIcon-root {
                        color: #ffffff;
                    }
                }

                .feedback-btn:hover {
                    background-color: #565656;
                    color: #ffffff;
                }
            }

            .input-box {
                textarea {
                    color: #ffffff;
                    background-color: #565656;
                }

                textarea::placeholder {
                    color: #ffffff;
                }
            }
        }
    }
}