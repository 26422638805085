.main-ai-container.login {
    width: 350px;
    height: 250px;
}

.ai-header.login {
    position: relative;
    text-align: center;
    height: 55px;
    width: 100%;
    margin-bottom: 10px;
}

.ai-logo.login {
    float: unset;
    height: 55px;
    object-fit: contain;
    overflow: hidden;
    padding: 0px 15px;
}

.ai-header-divider.login {
    width: 10%;
    padding: 10px;
    height: 2px;
    border-bottom: 2px solid #F9CB5A;
    margin: auto
}