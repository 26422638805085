body {
  .abacus-input-header {
    display: flex;
    flex-direction: row;
    padding: 25px 0px 0px 0px;

    .source-button-container {
      max-width: 47%;
    }

    .abacus-input-new-chat-btn {
      padding: 8px 20px 8px 20px;
      margin: 0px 6px 0px 0%;
      background-color: var(--nightModeBody);
      box-shadow: none;
      border: 2px solid #565656;
      color: #565656;
      border-radius: 6px;
      font-size: 0.9em;
      letter-spacing: 0.05em;
      font-family: "ManropeRegular";
      z-index: 10;
      display: flex;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      transition: all 0.3s linear;

      .abacus-input-new-chat-plus,
      .abacus-input-new-chat-hammer {
        transition: all 0.3s linear;
        margin: 0px 8px 0px 0px;
        height: 15px;
        width: 15px;
        color: #565656;
      }

      .abacus-input-new-chat-hammer.nightMode,
      .abacus-input-new-chat-plus.nightMode {
        color: #ffffff;
        --nightModeBody: #565656;
        transition: all 0.3s linear;
      }
    }

    .abacus-input-new-chat-btn.files {
      padding: 10px 20px 10px 20px;
      transition: all 0.3s linear;
    }

    .abacus-input-new-chat-btn:hover {
      background-color: #565656;
      color: #ffffff;
      transition: all 0.3s linear;

      .abacus-input-new-chat-plus,
      .abacus-input-new-chat-hammer {
        color: #ffffff;
        transition: all 0.3s linear;
      }
    }

    #abacus-souce-options {
      margin: 0px 6px 0px 0px;
      width: 100%;
      transition: all 0.3s linear;

      .MuiInputBase-root {
        border-radius: 6px;
        transition: all 0.3s linear;
      }

      .MuiSelect-nativeInput {
        top: -6px;
        transition: all 0.3s linear;
      }

      #abacus-souce-options-input {
        font-size: 0.9em;
        letter-spacing: 0.05em;
        font-family: "ManropeRegular";
        color: #565656;
        top: -4px;
        transition: all 0.3s linear;
      }

      #abacus-souce-options-select {
        width: 100%;
        text-align: left;
        padding: 11px 20px 9px 20px;
        border-radius: 6px;
        color: #565656;
        transition: all 0.3s linear;
      }

      .MuiOutlinedInput-notchedOutline {
        border-radius: 6px;
        border: 2px solid #565656;
        transition: all 0.3s linear;
      }
    }
  }

  #abacus-souce-options-item {

    .MuiCheckbox-root {
      padding: 0px 10px 0px 0px;
      transition: all 0.3s linear;
    }

    span {
      font-size: 0.9em;
      letter-spacing: 0.05em;
      font-family: "ManropeRegular";
      color: #565656;
      transition: all 0.3s linear;
    }

  }


  .abacus-user-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 0px 0px 20px 0px;
    transition: all 0.3s linear;
  }

  .abacus-action-container.user {
    width: 45px;
    margin: 0px 0px 0px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s linear;
  }

  .abacus-action-logo.user {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #206FCB;
    color: #ffffff;
    transition: all 0.3s linear;
  }

  .abacus-action-logo.user p {
    color: #ffffff;
    font-size: 1.3em;
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.03em;
    font-family: "ManropeRegular";
    transition: all 0.3s linear;
  }

  .abacus-user-message {
    background: #f1f1f1;
    border-radius: 12px;
    color: #535353;
    float: left;
    font-family: "ManropeLight";
    font-size: 0.9em;
    line-height: 1.6em;
    overflow: hidden;
    padding: 12px;
    text-align: left;
    word-wrap: break-word;
    transition: all 0.2s linear;
  }

  .abacus-user-message p {
    margin: 0px;
    text-align: left;
    font-family: "ManropeLight";
    transition: all 0.2s linear;
  }

  .abacus-user-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 0.3s linear;
  }

  .abacus-input-container {
    font-family: "ManropeLight";
    position: relative;
    width: 100%;
    margin: 10px 0px 20px 0px;
    display: flex;
    color: #535353;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #F4F4F4;
    transition: font-size 0.3s linear;

    .input-body {
      width: 100%;
      text-align: left;

      .source-list {
        font-family: "ManropeRegular";
        color: #00747C;
        border-radius: 24px;
        border: none;
        line-height: 1.2em;
        font-size: 0.85em;
        background-color: transparent;
        letter-spacing: 0.02em;
        margin: 0px 15px 15px 15px;
        resize: none;
        max-height: 300px;
        transition: font-size 0.3s linear, background-color 0.3s linear;
        width: 100%;
      }
    }

    .abacus-uploaded-files {
      width: 100%;
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
      flex-wrap: none;
      flex-direction: row;
      transition: all 0.3s linear;

      .abacus-uploaded-file {
        scrollbar-width: none;
        flex: 0 0 auto;
        text-align: left;
        background-color: #BCBCBC;
        padding: 15px;
        border-radius: 12px;
        width: 150px;
        margin: 0px 8px 15px 8px;
        transition: all 0.3s linear;

        p {
          font-size: 0.9em;
          font-family: "ManropeRegular";
          line-break: anywhere;
          transition: all 0.3s linear;
        }
      }
    }
  }

  .abacus-input-box {
    font-family: "ManropeLight";
    position: relative;
    width: 100%;
    display: flex;
    color: #535353;
    border-radius: 12px;
    display: flex;
    align-items: center;
    background-color: #F4F4F4;
    transition: all 0.3s linear;

    .abacus-input-enter-chat-btn {
      padding: 10px 15px 10px 15px;
      margin: 10px;
      background-color: var(--nightModeBody);
      box-shadow: none;
      border: 2px solid #565656;
      color: #565656;
      border-radius: 6px;
      font-size: 0.85em;
      letter-spacing: 0.05em;
      font-family: "ManropeRegular";
      z-index: 10;
      display: flex;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      transition: all 0.3s linear;
    }

    .abacus-input-enter-chat-btn:hover {
      background-color: #565656;
      color: #ffffff;
      transition: all 0.3s linear;
    }
  }

  .abacus-input-box.true {
    background-color: #535353;

    textarea::placeholder {
      color: #535353;
    }
  }

  .abacus-input-box textarea {
    font-family: "ManropeRegular";
    color: #535353;
    border-radius: 24px;
    border: none;
    line-height: 1.6em;
    background-color: transparent;
    letter-spacing: 0.02em;
    margin: 15px 15px 0px 15px;
    resize: none;
    max-height: 300px;
    transition: font-size 0.3s linear, background-color 0.3s linear;
    width: 100%;
  }

  .abacus-input-box textarea::placeholder {
    transition: all 0.3s linear;
    color: #535353;
  }

  .abacus-input-box textarea:focus {
    outline: none;

    .abacus-input-box {
      transition: all 0.3s linear;
    }
  }

  .abacus-input-upload {
    height: 22px;
    width: 22px;
    padding: 15px;
    cursor: pointer;
    color: #206FCB;
    transition: all 0.3s linear;
  }

  .abacus-input-upload:hover {
    color: #535353
  }
}