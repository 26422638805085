body.small {
    .abacus-ai-history-body.true {
        height: 100%;
    }

    /**Navbar**/
    .abacus-navbar-logo {
        height: 8%;
    }

    .abacus-navbar-size-btn {
        height: 45px;
        width: 45px;
    }

    .abacus-navbar-size-btn .line {
        width: 24px;
    }

    .abacus-navbar-size-btn .line:nth-child(2) {
        width: 28px;
    }

    .abacus-navbar-multi-tool-body.is-active,
    .abacus-navbar-new-chat-btn.is-active,
    .abacus-navbar-main.is-active {
        width: 200px;
    }

    .abacus-navbar-new-chat-plus,
    .abacus-navbar-multi-tool-icon {
        height: 19px;
        width: 19px;
    }

    .abacus-navbar-new-chat-btn.is-active,
    .abacus-navbar-multi-tool-heading.is-active {
        font-size: 0.85em;
    }

    .abacus-navbar-options-body.is-active {
        width: 120px
    }

    .abacus-navbar-options-icon {
        height: 17px;
        width: 17px;
    }

    .abacus-navbar-options-btn.is-active {
        font-size: 0.75em;
    }

    .abacus-navbar-multi-tool-content-headings.is-active {
        font-size: 0.8em;
    }

    .colorSelector {
        height: 15px;
        width: 30px;
    }

    .abacus-ui-size-slider.MuiSlider-root,
    .abacus-message-width-slider.MuiSlider-root {
        width: 130px !important;
        left: 18px;
        transition: all 0.3s linear;
    }

    .abacus-navbar-options-body-btn.is-active {
        margin: 0px 0px 0px 20px;
    }

    /**AI Action Center**/
    .abacus-action-container {
        width: 48px;
    }

    .abacus-action-logo {
        width: 46px;
        height: 46px;
    }

    .abacus-action-logo.user p {
        font-size: 1.1em;
    }

    .abacus-action-feedback-icon {
        width: 19px;
        height: 19px;
    }

    .abacus-action-settings-icon {
        width: 19px;
        height: 19px;
    }

    .abacus-action-settings-btn {
        width: 46px;
        font-size: 0.75em;
    }

    /**AI Message**/
    .abacus-message,
    .abacus-message-blob,
    .abacus-user-message {
        font-size: 0.85em;
        flex-shrink: 50;
    }

    .abacus-input-box textarea {
        font-size: 0.85em;
        margin: 12px;
    }

    .abacus-input-upload {
        height: 19px;
        width: 19px;
    }

    .abacus-input-container {
        .abacus-uploaded-file {
            width: 120px;

            p {
                font-size: 0.8em;
            }
        }
    }

    .abacus-sources-header {
        .abacus-message-sources-icon {
            width: 17px;
            height: 17px;
        }


        h2 {
            font-size: 1.2em;
        }
    }

    .source-name {
        font-size: 0.8em;
    }

    .source-lines {
        font-size: 0.75em;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 0.9em;
    }

    .abacus-launch-container {
        .content {
            padding: 20px;
        }
        
        .heading {
            font-size: 1.8em;
            padding: 0px 0px 18px 0px;
        }

        p {
            font-size: 0.9em;
        }

        .abacus-launch-button {
            padding: 12px 22px 12px 22px;
            font-size: 0.9em;
        }
    }

    .abacus-newChat-container {
        .content {
            padding: 20px;
        }

        .heading {
            font-size: 1.8em;
            padding: 0px 0px 18px 0px;
        }

        p {
            font-size: 0.9em;
        }

        .body {
            display: flex;
            flex-direction: row;

            .start-tiles {
                padding: 20px;
                p {
                    font-size: 0.9em;
                }
            }
        }
    }
}